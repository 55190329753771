exports.components = {
  "component---src-components-blog-blog-post-js": () => import("./../../../src/components/Blog/BlogPost.js" /* webpackChunkName: "component---src-components-blog-blog-post-js" */),
  "component---src-components-quiz-quiz-page-js": () => import("./../../../src/components/Quiz/QuizPage.js" /* webpackChunkName: "component---src-components-quiz-quiz-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogg-js": () => import("./../../../src/pages/blogg.js" /* webpackChunkName: "component---src-pages-blogg-js" */),
  "component---src-pages-fysik-js": () => import("./../../../src/pages/fysik.js" /* webpackChunkName: "component---src-pages-fysik-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kemi-js": () => import("./../../../src/pages/kemi.js" /* webpackChunkName: "component---src-pages-kemi-js" */),
  "component---src-pages-matematik-js": () => import("./../../../src/pages/matematik.js" /* webpackChunkName: "component---src-pages-matematik-js" */),
  "component---src-pages-om-js": () => import("./../../../src/pages/om.js" /* webpackChunkName: "component---src-pages-om-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-programmering-js": () => import("./../../../src/pages/programmering.js" /* webpackChunkName: "component---src-pages-programmering-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-verktyg-area-cirkel-js": () => import("./../../../src/pages/verktyg/area-cirkel.js" /* webpackChunkName: "component---src-pages-verktyg-area-cirkel-js" */),
  "component---src-pages-verktyg-js": () => import("./../../../src/pages/verktyg.js" /* webpackChunkName: "component---src-pages-verktyg-js" */),
  "component---src-pages-verktyg-medelvarde-js": () => import("./../../../src/pages/verktyg/medelvarde.js" /* webpackChunkName: "component---src-pages-verktyg-medelvarde-js" */)
}

